import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// Externals
import PropTypes from 'prop-types';
import validate from 'validate.js';
import compose from 'recompose/compose';
import queryString from 'query-string';

// Material helpers
import { withStyles } from '@material-ui/core/styles/index';

// Material components
import Button from '@material-ui/core/Button/index';
import CircularProgress from '@material-ui/core/CircularProgress/index';
import Divider from '@material-ui/core/Divider/index';
import Grid from '@material-ui/core/Grid/index';
import LinearProgress from '@material-ui/core/LinearProgress/index';
import TextField from '@material-ui/core/TextField/index';
import Typography from '@material-ui/core/Typography/index';

// Shared components
import Link from 'components/Link';

// Translations
import translate from 'helpers/translate';

// Component styles
import styles from './styles';
import Quote from 'components/Layout/Quote';

// Form validation schema
import schema from './schema';

// Actions
import confirmResetPassword from 'services/user/confirmResetPassword';
import { signIn } from 'store/authentication/actionsCreator';

class ResetPasswordConfirmation extends Component {
  state = {
    loading: false,
    values: {
      password: ''
    },
    touched: {
      password: false
    },
    errors: {
      password: null
    },
    isValid: false,
    submitError: null,
    submitted: false,
    email: '',
    token: ''
  };

  validateForm = () => {
    const newState = { ...this.state };

    const errors = validate(newState.values, schema);

    newState.errors = errors || {};
    newState.isValid = errors ? false : true;

    this.setState(newState);
  };

  handleChange = (field, value) => {
    const newState = { ...this.state };

    newState.submitError = null;
    newState.touched[field] = true;
    newState.values[field] = value;

    this.setState(newState, this.validateForm);
  };

  handleSendRequest = async (e) => {
    this.setState({ loading: true });
    e.preventDefault();

    const { strings } = this.props;
    const { values } = this.state;

    try {
      await confirmResetPassword(this.state.email, this.state.token, values.password);

      this.setState({ submitted: true });

      console.log('password modified');
      await this.props.signIn(this.state.email, values.password, (result) => {
        console.log('sign in result', result);
      });
    } catch (error) {
      this.setState({
        submitError: strings.errors.wrongCredentialsError
      });
    }

    this.setState({ loading: false, submitted: true });
  };

  componentDidMount() {
    const { email, token } = queryString.parse(this.props.location.search);
    this.setState({ email, token });
  }

  render() {
    const { classes, strings } = this.props;
    const { values, touched, errors, isValid, submitError, isAccessedOrRefreshed, loading, submitted } = this.state;

    const showPasswordError = touched.password && errors.password;

    return (
      <div className={classes.root}>
        {isAccessedOrRefreshed ? (
          <div className={classes.refreshWrapper}>
            <img alt="ShareABike" className={classes.refreshImage} src="/images/logos/logo-large.png" />
            {submitError ? (
              <Typography className={classes.submitError} variant="body2">
                {submitError}
              </Typography>
            ) : (
              <LinearProgress className={classes.refreshProgress} />
            )}
          </div>
        ) : (
          <Grid className={classes.grid} container>
            <Grid className={classes.quoteWrapper} item lg={6}>
              <Quote />
            </Grid>
            <Grid className={classes.content} item lg={6} xs={12}>
              <div className={classes.contentHeader} />
              <div className={classes.contentBody}>
                <form className={classes.form}>
                  <Typography className={classes.title} variant="h2">
                    {strings.title}
                  </Typography>
                  <Typography className={classes.subtitle} variant="body1">
                    {strings.subtitle}
                  </Typography>
                  <div className={classes.fields}>
                    <TextField
                      InputLabelProps={{
                        shrink: true
                      }}
                      className={classes.textField}
                      label={strings.email.label}
                      name="email"
                      value={this.state.email}
                      type="text"
                      variant="outlined"
                      readOnly
                    />
                    <TextField
                      InputLabelProps={{
                        shrink: true
                      }}
                      className={classes.textField}
                      error={showPasswordError ? true : false}
                      helperText={showPasswordError ? strings.password.error : ''}
                      label={strings.password.label}
                      name="password"
                      onChange={(event) => this.handleChange('password', event.target.value)}
                      type="password"
                      value={values.password}
                      variant="outlined"
                    />
                  </div>
                  {submitError && (
                    <Typography className={classes.submitError} variant="body2">
                      {submitError}
                    </Typography>
                  )}
                  <div className={classes.wrapper}>
                    <Button
                      className={classes.button}
                      color="primary"
                      disabled={!isValid || loading}
                      onClick={this.handleSendRequest}
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      {strings.action}
                    </Button>
                    {(loading || this.props.authenticationLoading) && <CircularProgress className={classes.progress} />}
                    {submitted && (
                      <Typography className={classes.subtitle} variant="body1" sx={{ mt: 2 }}>
                        {strings.nextAction}
                      </Typography>
                    )}
                  </div>
                  <Divider className={classes.divider} variant="fullWidth" />
                  <Link component="button" href="/sign-in" variant="body1">
                    {strings.signIn}
                  </Link>
                  <span style={{ marginLeft: 8, marginRight: 8 }}>&mdash;</span>
                  <Link component="button" href="/sign-up" variant="body1">
                    {strings.signUp}
                  </Link>
                  <div className={classes.callToAction}>
                    <div>
                      &mdash;{' '}
                      <a
                        className={classes.CTALink}
                        target="_blank"
                        href="https://professionnels.provelo.org/les-velos-partages-shareabike/"
                        variant="body1"
                      >
                        Vous êtes un utilisateur ShareABike
                      </a>
                    </div>
                    <div>
                      &mdash;{' '}
                      <a
                        className={classes.CTALink}
                        target="_blank"
                        href="https://professionnels.provelo.org/services/achat-et-gestion-de-flotte/"
                      >
                        Vous êtes une entreprise
                      </a>
                    </div>
                    <div>
                      &mdash;{' '}
                      <a
                        className={classes.CTALink}
                        target="_blank"
                        href="https://professionnels.provelo.org/services/share-a-bike-velo-libre-service/"
                        variant="body1"
                      >
                        Vous êtes un service public
                      </a>
                    </div>
                  </div>
                </form>
              </div>
              <div className={classes.contentFooter}>
                <Divider className={classes.divider} variant="fullWidth" />
                <div className={classes.footerLinks}>
                  <Link component="button" href="/sign-in" variant="body1">
                    {strings.signIn}
                  </Link>
                  <span style={{ color: '#66788A' }}>&nbsp;-&nbsp;</span>
                  <Link component="button" href="/contact-us" variant="body1">
                    {strings.contactUs}
                  </Link>
                  <span style={{ color: '#66788A' }}>&nbsp;-&nbsp;</span>
                  <Link component="button" href="/terms-of-use" target="_blank" variant="body1">
                    {strings.CGU}
                  </Link>
                  <span style={{ color: '#66788A' }}>&nbsp;-&nbsp;</span>
                  <Link component="button" href="/help" target="_blank" variant="body1">
                    {strings.help}
                  </Link>
                  <span style={{ color: '#66788A' }}>&nbsp;-&nbsp;</span>
                  <Link component="button" href="/about" variant="body1">
                    {strings.about}
                  </Link>
                </div>
              </div>
            </Grid>
          </Grid>
        )}
      </div>
    );
  }
}

ResetPasswordConfirmation.propTypes = {
  classes: PropTypes.object.isRequired,
  strings: PropTypes.object,
  signIn: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  return {
    authenticationLoading: state.authentication.loading
  };
};

const mapDispatchToProps = {
  signIn
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withStyles(styles),
  translate('ResetPasswordConfirmation')
)(ResetPasswordConfirmation);
