const confirmResetPassword = async (email: string, resetPasswordToken: string, password: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/api/v1/users/confirm-reset-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: email,
          resetPasswordToken: resetPasswordToken,
          password: password
        })
      });

      if (response.ok) {
        resolve({});
      } else {
        const json = await response.json();
        reject(json);
      }
    } catch (error) {
      reject(error);
    }
  });
};

export default confirmResetPassword;
